<template>
    <div class="old-material">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="header">
                    <div class="modal-header modal-header_no-border">
                        <h3 class="block-title">Устарело</h3>
                        <button
                            type="button"
                            class="btn-block-option close-modal"
                            @click="$modal.hide('show-comment')"
                        >
                            ×
                        </button>
                    </div>
                    <ul v-if="!isLoading" class="old-material__tabs">
                        <li
                            v-for="tab in tabs"
                            :key="tab.status"
                            class="old-material__tab"
                            :class="{
                                'old-material__tab--active':
                                    status === tab.status
                            }"
                            @click="changeStatus(tab.status)"
                        >
                            {{ tab.name }}
                            <span class="old-material__tab-count">{{
                                tab.count
                            }}</span>
                        </li>
                    </ul>
                </div>

                <div v-if="!isLoading" class="block-content content">
                    <div
                        v-if="!isLoadingAuthor"
                        class="old-material__container"
                    >
                        <show-comment-author
                            v-for="author in authors"
                            :author="author"
                            :key="author.id"
                            @fetch-status="fetchStatus($event)"
                        ></show-comment-author>
                    </div>
                    <loading-spinner v-else />
                </div>
                <loading-spinner v-else />
            </div>
        </div>
    </div>
</template>

<script>
import session from '@/api/session'

export default {
    name: 'ShowCommentModal',
    components: {
        ShowCommentAuthor: () => import('./ShowCommentAuthor'),
        LoadingSpinner: () => import('@/components/LoadingSpinner')
    },
    data () {
        return {
            isLoading: true,
            isLoadingAuthor: false,
            authors: [],
            tabs: [],
            materialId: this.$route.params.material_id,
            status: 0
        }
    },
    props: ['material'],
    async created () {
        await this.getTabs()
        this.tabs.length
            ? (this.status = this.tabs[0].status)
            : (this.status = 0)
        await session
            .get(
                `/api/v1/material-suggestion/?material_id=${this.materialId}&status=${this.status}`
            )
            .then(response => {
                this.authors = response.data
            })
        this.isLoading = false
    },
    methods: {
        async getTabs () {
            await session
                .get(
                    `/api/v1/material-suggestion/default_data/?material_id=${this.materialId}`
                )
                .then(response => {
                    this.tabs = response.data.filter(item => item.count)
                })
        },
        async changeStatus (index) {
            if (this.status !== index) {
                this.isLoadingAuthor = true
                this.status = index
                const response = await session.get(
                    `/api/v1/material-suggestion/?material_id=${this.materialId}&status=${this.status}`
                )
                this.authors = response.data
                this.isLoadingAuthor = false
            }
        },
        async fetchStatus (data) {
            this.isLoadingAuthor = true
            await session.post(
                `/api/v1/material-suggestion/${data.id}/set_status/`,
                {
                    status: data.status
                }
            )
            await this.getTabs()
            const changedAuthor = this.authors.find(
                item => item.id === data.id
            )
            changedAuthor.status = data.status
            this.authors = this.authors.filter(
                item => item.status === this.status
            )
            if (!this.authors.length) {
                await this.changeStatus(data.status)
            }
            if (data.status === 0) {
                this.material.suggestion++
            } else if (data.old_status === 0) {
                this.material.suggestion--
            }
            this.isLoadingAuthor = false
        }
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.v--modal-overlay .v--modal-box {
    overflow: visible;
}

.close-modal {
    top: 25px;
    right: 30px;
}

.modal-dialog {
    max-width: 840px;
    margin: 0;

    @include below($tablet) {
        max-width: none;
    }
}

.modal-content {
    padding: 30px;
    max-height: 70vh;
    min-height: 70vh;

    @include below($mobile) {
        padding: 15px;
    }
}

.header {
    background: #fff;
}

.modal-header {
    padding: 0;
    margin-bottom: 24px;

    @include below($mobile) {
        margin-bottom: 15px;
    }
}

.block-content {
    padding: 0;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
    }
}

.block-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.old-material {
    &__container {
        & > * {
            margin-top: 32px;
        }
    }
    &__tabs {
        display: flex;
        border-bottom: 1px solid $border-gray;
        list-style-type: none;
        padding-left: 0;
        line-height: 22px;
        margin: 0 -30px;
        padding: 0 30px;
        overflow-x: auto;
        overflow-y: hidden;

        & > li + li {
            margin-left: 32px;
        }
    }
    &__tab {
        color: $text-gray;
        position: relative;
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 600;
        letter-spacing: 0.06em;
        cursor: pointer;
        white-space: nowrap;
        user-select: none;
        padding-bottom: 15px;

        &:hover {
            color: $text-darkgray;
        }

        &--active {
            color: #000;
            &::before {
                content: "";
                position: absolute;
                height: 3px;
                width: 100%;
                bottom: -1px;
                left: 0;
                background: $gold;
            }
        }
        &:hover {
            color: #000;
        }

        &-count {
            font-size: 0.875rem;
            font-weight: 600;
            letter-spacing: 0.06em;
            color: $text-gray;
            margin-left: 5px;
        }
    }
}
</style>
